import React, { useEffect } from "react"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Title from "../components/Layout/Title"

const Success = () => {
  // Redirect user to the Homepage
  useEffect(() => {
    setTimeout(() => {
      navigate("/")
    }, 5000)
  }, [])

  return (
    <Layout flexGrow={true}>
      <SEO title="Contact" />
      <div
        className="container"
        style={{ display: "flex", alignItems: "center", height: "100%" }}>
        <div className="columns--2">
          <div className="column">
            <Title upper="Message" lower="Envoyé" />
            <p>Votre message à bien été envoyé.</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Success
